import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { Spacer, Input, Button, Container, Text } from "@nextui-org/react";
import { useNavigate } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { app } from "../firebase/config";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Logo from '../assets/logo.png'

const auth = getAuth(app);

export default function SignInPage(){
    const navigate = useNavigate();
    
    const { control, handleSubmit } = useForm({
        defaultValues: {
          email: "",
          password: "",
        },
      });
    
    const invalidLogin = () => toast("Invalid Login");

    const onSubmit = data => {
    signInWithEmailAndPassword(auth, data.email, data.password)
    .then((userCredential) => {
    const user = userCredential.user;
    localStorage.setItem('uid', user.uid);
    navigate(`/home/${user.uid}`)
        })
    .catch((error) => {
        if (error.code === "auth/wrong-password" || 
            error.code === "auth/user-not-found" || 
            error.code === "auth/invalid-email") {
            invalidLogin();
        }
        else {
            console.log(error);
        }
    });
    }

    return (
        <>
        <Spacer y={2}/>
        <img src={Logo} alt="Logo" />
        <Container align="center" justify="center">
        <Spacer y={2}/>
        <Text h1 size={60} 
            css={{textGradient: "45deg, $blue600 -20%, $pink600 50%",}}
            weight="bold">
            Sign in to your LiquiDex account</Text>
        </Container>
        <Spacer y={6}/>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Container direction="column" align="center">
              <Controller
                name="email"
                control={control}
                render={({ field }) => (
                  <Input aria-label="email" label="Email"{...field} />
                )}
              />
              <Spacer y={1}/>
                <Controller
                    name="password"
                    control={control}
                    render={({ field }) => (
                      <Input.Password aria-label="password" label="Password"{...field} />
                    )}
                />
                <Spacer y={1}/>
                <Spacer y={2}/>
            <Button type="submit">Sign In</Button>
            </Container>
          </form>
          <Container>
          <ToastContainer position="bottom-center"
                        autoClose={2000}
                        hideProgressBar={false}
                        newestOnTop={true}
                        closeOnClick
                        pauseOnFocusLoss
                        pauseOnHover/>
            </Container>
        </>
  )}
