import React from 'react';
import './UnderConstruction.scss'

const objects = ['line lineOne', 'line lineTwo', 'line lineThree', 'stand', 'weight', 'cabin', 'arm'];

function UnderConstruction() {
  let n = 0;

  return (
    <div>
      <h1>&lt; building /&gt;</h1>
      <p>Working day and night!</p>

      <div id="outerCraneContainer">
        <div className="buildings">
          {[...Array(5)].map(() => (
            <div key={n++} />
          ))}
        </div>

        <div className="crane craneThree">
          {objects.map((i, index) => (
            <div key={index} className={i} />
          ))}
        </div>

        <div className="crane craneTwo">
          {objects.map((i, index) => (
            <div key={index} className={i} />
          ))}
        </div>

        <div className="crane craneOne">
          {objects.map((i, index) => (
            <div key={index} className={i} />
          ))}
        </div>
      </div>

      <a rel="license" href="http://creativecommons.org/licenses/by-nc-sa/4.0/" id="license">
        <img alt="Creative Commons License" style={{ borderWidth: 0 }} src="https://i.creativecommons.org/l/by-nc-sa/4.0/88x31.png" />
      </a>
    </div>
  );
}

export default UnderConstruction;
