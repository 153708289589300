import { Container, Spacer, Button, Text, Link } from "@nextui-org/react";
import Logo from '../assets/logo.png'

export default function InitialPage() {
    return (
        <>
        <Container>
            <Container justify="start">
                <Spacer y={2} />
                <img src={Logo} alt="Logo" />
            </Container>
            <Container align="center" justify="center">
                <Spacer y={2} />
                    <Text h1>Please select your category:</Text>
                <Spacer y={4} />
                    <Link href="/investor" passHref>
                        <Button size="xl">Investor</Button>
                    </Link>
                <Spacer y={2} />
                    <Link href="/startup" passHref>
                        <Button size="xl">Startup</Button>
                    </Link>
            </Container>
                <Spacer y={6} />
            <Container align="center" justify="center">
            <Link href="/signin" passHref>
                        <Button color="gradient" size="xl" ghost>Sign In</Button>
                    </Link>
                <Spacer y={2} />
            </Container>
        </Container>
        </>
      );
}