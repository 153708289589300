import NavBar from "../Components/NavBar";
import { useEffect, useState, useRef } from "react";
import { Card, Text, Spacer, Button, Grid, Modal } from "@nextui-org/react";
import { useNavigate } from "react-router-dom";
import { collection, getDocs, getFirestore, getDoc, doc, setDoc } from "firebase/firestore";
import { app } from "../firebase/config";
import { ToastContainer, toast } from 'react-toastify';
import emailjs from '@emailjs/browser';
import 'react-toastify/dist/ReactToastify.css';

const db = getFirestore(app);

export default function EquitiesPage() {
    const [equities, setEquities] = useState([]);
    const [visible, setVisible] = useState(false);
    const navigate = useNavigate();
    const equityRef = useRef(null);

  function selectEquity(equity) {
    equityRef.current = equity;
    console.log(equity)
    setVisible(true);
  }

  const closeHandler = () => {
    setVisible(false);
  };

  const insufficientFunds = () => toast("Insufficient Funds");

  function sendNftEmail({data, equity}){

    if (equity.template === 1){
   emailjs.send("service_t2ubks6","template_6qsxajk",{
        to_name: data.name,
        equity_name: equity.name,
        to_email: data.email,
        }, "GV8QSdrtrVE0jXj1E")
        .then((result) => {
            console.log(result.text);
        }, (error) => {
            console.log(error.text);
        });;
    }
    if (equity.template === 2){
    emailjs.send("service_t2ubks6","template_5pk6qip",{
      to_name: data.name,
      equity_name: equity.name,
      to_email: data.email,
      }, "GV8QSdrtrVE0jXj1E")
      .then((result) => {
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      });
    }

    if (equity.template === 3){
    emailjs.send("service_t2ubks6","template_nd95rqk",{
      to_name: data.name,
      equity_name: equity.name,
      to_email: data.email,
      }, "GV8QSdrtrVE0jXj1E")
      .then((result) => {
          console.log(result.text);
      }, (error) => {
        console.log(error.text);
      });;
    }

    if (equity.template === 4){
    emailjs.send("service_t2ubks6","template_02tgs2s",{
      to_name: data.name,
      equity_name: equity.name,
      to_email: data.email,
      }, "GV8QSdrtrVE0jXj1E")
      .then((result) => {
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      });;
    }
    if (equity.template === 5){
      emailjs.send("service_t2ubks6","template_o7uc224",{
        to_name: data.name,
        equity_name: equity.name,
        to_email: data.email,
        }, "GV8QSdrtrVE0jXj1E")
        .then((result) => {
            console.log(result.text);
        }, (error) => {
            console.log(error.text);
        });;
  }
}

  async function handlePurchaseEquity() {
    closeHandler();
    const uid = localStorage.getItem("uid");
    const equity = equityRef.current;
    const docSnap = await getDoc(doc(db, "investors", uid))
    const data = docSnap.data();
    const newBalance = data.balance - equity.price;
    if (newBalance >= 0) {
        await setDoc(doc(db, "investors", uid), {
            balance: newBalance
        }, { merge: true })
        sendNftEmail( {data, equity} );
        navigate(`/confirmation/${uid}`);
        }
    else {
        insufficientFunds();
    }
    }

  useEffect(() => {
    if (localStorage.getItem("uid") === null) {
      navigate("/");
    }

    const fetchEquities = async () => {
      const querySnapshot = await getDocs(collection(db, "equities"));
      const data = querySnapshot.docs.map((doc) => doc.data());
      setEquities(data);
    };

    fetchEquities();
  }, [navigate]);

  return (
    <>
      <Modal
        closeButton
        blur
        aria-labelledby="modal-title"
        open={visible}
        onClose={closeHandler}
      >
        <Modal.Header>
          <Text id="modal-title" size={18}>
            Are you sure you want to purchase this equity?
          </Text>
        </Modal.Header>
        <Modal.Footer>
          <Button auto flat color="error" onPress={closeHandler}>
            No
          </Button>
          <Button auto onPress={handlePurchaseEquity}>
            Yes
          </Button>
        </Modal.Footer>
      </Modal>
      <NavBar />
      <Spacer y={2} />
      <Text h2>Equities</Text>
      <Spacer y={2} />
      <Grid.Container gap={3} justify="center">
        {equities.map((equity) => (
          <Grid sm="true" key={equity.name + "-" + equity.price}>
            <Card
              css={{ alignItems: "space-between", height: "100px" }}
            >
              <Text weight="bold">{equity.name} </Text>
              <Text>${equity.price}</Text>
              <Button onPress={() => selectEquity(equity)}>
                Purchase Equity
              </Button>
              <Spacer y={1} />
            </Card>
          </Grid>
        ))}
      </Grid.Container>
      <ToastContainer position="bottom-center"
                        autoClose={2000}
                        hideProgressBar={false}
                        newestOnTop={true}
                        closeOnClick
                        pauseOnFocusLoss
                        pauseOnHover/>
    </>
  );
}
