import { Container, Text, Spacer } from "@nextui-org/react";
import NavBar from "../Components/NavBar";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Animation from "../styles/Animation";

export default function HomePage() {

    const navigate = useNavigate();

    useEffect(() => {
        if (localStorage.getItem("uid") === null) {
          navigate("/");
        }
        });

        return (
            <>
                <NavBar/>
                <Spacer y={2}/>
                <Container>
                    <Text h2> Welcome to LiquiDex</Text>
                </Container>
                <Spacer y={20}/>
                <Animation/>
                <Text h3>Coming Soon...</Text>
            </>
        );
        }