import { useForm, Controller } from "react-hook-form";
import { Input, Button, Textarea, Radio, Container, Spacer, Text } from "@nextui-org/react";
import { getAuth, createUserWithEmailAndPassword } from "firebase/auth";
import { app } from "../firebase/config";
import { useNavigate } from "react-router-dom";
import { doc, setDoc, getFirestore } from "firebase/firestore";
import Logo from '../assets/logo.png'

const db = getFirestore(app);

const auth = getAuth(app);

function AccreditedInvestor({ field }) {
    return (
      <Radio.Group 
        css={{alignItems: "center"}}
        orientation="horizontal"
        label="Are you an accredited investor?"
        value={field.value}
        onChange={field.onChange}
        defaultValue={false}
      >
        <Radio value={true}>Yes</Radio>
        <Radio value={false}>No</Radio>
      </Radio.Group>
    );
  }

  export default function InvestorSetup() {

    const navigate = useNavigate();
    
    const { control, handleSubmit } = useForm({
        defaultValues: {
          name: "",
          email: "",
          password: "",
          confirmPassword: "",
          boolAI: false,
          notes: "",
        },
      });

      const onSubmit = async data => {
        if (data.password === data.confirmPassword){
          try {
            const userCredential = await createUserWithEmailAndPassword(auth, data.email, data.password)
            const user = userCredential.user;
            localStorage.setItem('uid', user.uid);
            localStorage.setItem('userType', 'investor');
      
            await setDoc(doc(db, "investors", user.uid), {
              name: data.name,
              email: data.email,
              boolAI: data.boolAI,
              notes: data.notes,
              balance: 50000
            }, { merge: true })
            .then(navigate(`/home/${user.uid}`));
          } catch (error) {
            console.log(error);
          }
        } else {
          alert("Password and Confirm Password do not match")
        }
      };

    return (
        <>
        <Spacer y={1}/>
        <img src={Logo} alt="Logo" />
        <Container align="center" justify="center">
        <Text h1 size={60} 
            css={{textGradient: "45deg, $blue600 -20%, $pink600 50%",}}
            weight="bold">
            Investor Account Setup</Text>
        </Container>
        <Spacer y={1}/>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Container direction="column" align="center">
              <Controller
                name="name"
                control={control}
                render={({ field }) => (
                  <Input aria-label="name" label="Name" {...field} />
                )}
              />
              <Spacer y={1}/>
              <Controller
                name="email"
                control={control}
                render={({ field }) => (
                  <Input aria-label="email" label="Email"{...field} />
                )}
              />
              <Spacer y={1}/>
              <Container style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                <Controller
                    name="password"
                    control={control}
                    render={({ field }) => (
                      <Input.Password aria-label="password" label="Password"{...field} />
                    )}
                />
                <Spacer y={1}/>
                <Controller
                    name="confirmPassword"
                    control={control}
                    render={({ field }) => (
                    <Input.Password aria-label="confirmPassword" label="Confirm Password" {...field} />
                    )}
                />

              </Container>
              <Spacer y={1}/>
              <Container style={{ display: "flex", justifyContent: "center", alignItems: "center"}}>
                <Controller
                    name="boolAI"
                    control={control}
                    render={({ field }) => (
                    <AccreditedInvestor aria-label="boolAI" field={field} />)}
                />
                </Container>
              <Spacer y={1}/>
              <Controller
                name="notes"
                control={control}
                render={({ field }) => (
                  <Textarea
                    label="Notes (optional)"
                    placeholder="Please enter here"
                    aria-label="notes"
                    {...field}
                  />
                )}
              />
            <Spacer y={2}/>
            <Button type="submit">Continue</Button>
            </Container>
          </form>
        </>
  )}
