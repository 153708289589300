import React from 'react';
import './Animation.scss'; 

function Animation() {
  return (
    <div className="container">
      <div className="holder"><div className="box"></div></div>
      <div className="holder"><div className="box"></div></div>
      <div className="holder"><div className="box"></div></div>
    </div>
  );
}

export default Animation;
