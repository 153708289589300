import { useParams } from "react-router-dom";
import { Container, Text, Spacer } from "@nextui-org/react"
import { doc, getDoc, getFirestore } from "firebase/firestore";
import { app } from "../firebase/config";
import { useEffect, useState } from "react";

const db = getFirestore(app);

export default function ConfirmationPage(props) {
    const [investorData, setInvestorData] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const { uid } = useParams();
    
    useEffect(() => {
        async function fetchData() {
            try {
                const docSnap = await getDoc(doc(db, "investors", uid));
                setInvestorData(docSnap.data());
                setIsLoading(false);
            } catch (error) {
                console.error(error);
            }
        }

        fetchData();
    }, [uid]);


    if (isLoading) {
        return <div>Loading...</div>;
    }

    return (
        <Container>
            <Spacer y={2} />
            <Text h3>A confirmation email has been sent to {investorData.email} containing the PNG you will mint to trade on the Liquidex Marketplace</Text>
            <Spacer y={4} />
            <Text h3>Your new balance is ${investorData.balance}</Text>
        </Container>
    );
}
