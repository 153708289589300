import './App.css';
import { ThemeProvider as NextThemesProvider } from 'next-themes';
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { createTheme, NextUIProvider } from "@nextui-org/react"
import { useState } from 'react';
import HomePage from './Pages/HomePage';
import EquitiesPage from './Pages/EquitiesPage';
import AboutPage from './Pages/AboutPage';
import InitialPage from './Pages/InitialPage';
import InvestorSetup from './Pages/InvestorSetup';
import StartupSetup from './Pages/StartupSetup';
import UnderConstruction from './styles/UnderConstruction';
import SignInPage from './Pages/SignInPage';
import ConfirmationPage from './Pages/ConfirmationPage';

const darkTheme = createTheme({
  type: 'dark'
});

function App() {
  const [equity, setEquity] = useState({});

  function handleEquityChange(value) {
    setEquity(value);
    console.log(equity)
  }

  const router = createBrowserRouter([
    {
      path: "/",
      element: <InitialPage/>,
    },
    {
      path: "/investor",
      element: <InvestorSetup/>,
    },
    {
      path: "/startup",
      element: <StartupSetup/>,
    },
    {
      path: "/signin",
      element: <SignInPage/>,
    },
    {
      path: "/home/:uid",
      element: <HomePage/>,
    },
    {
      path: "/equities/:uid",
      element: <EquitiesPage equity={equity} />,
    },
    {
      path: "/about/:uid",
      element: <AboutPage/>,
    }, 
    {
      path: "/confirmation/:uid",
      element: <ConfirmationPage equity={equity} />,
    }
  ]);

  console.log(equity)
  
  return (
    <NextThemesProvider
      attribute="class"
      value={{ dark: darkTheme.className }}
      >
      <NextUIProvider>
        <div className="App">
          <RouterProvider router={router} onEquityChange={handleEquityChange} />
        </div>
      </NextUIProvider>
    </NextThemesProvider>
  );
}

export default App;
