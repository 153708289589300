import { useForm, Controller } from "react-hook-form";
import { Input, Button, Textarea, Radio, Container, Spacer, Text } from "@nextui-org/react";
import { getAuth, createUserWithEmailAndPassword } from "firebase/auth";
import { app } from "../firebase/config";
import { useNavigate } from "react-router-dom";
import { doc, setDoc, getFirestore } from "firebase/firestore";
import Logo from '../assets/logo.png'

const auth = getAuth(app);
const db = getFirestore(app);

function EquityPercent({ field }) {
    return (
      <Radio.Group 
        css={{alignItems: "center"}}
        orientation="horizontal"
        label="Please select equity percentage"
        value={field.value}
        onChange={field.onChange}
        defaultValue={false}>

        <Radio value={1}>1%</Radio>
        <Radio value={2}>2%</Radio>
        <Radio value={5}>5%</Radio>
        <Radio value={10}>10%</Radio>
        <Radio value={25}>25%</Radio>
        <Radio value={50}>50%</Radio>
        <Radio value={"other"}>Other</Radio>
      </Radio.Group>
    );
  }

  export default function StartupSetup() {
    const navigate = useNavigate();
    
    const { control, handleSubmit } = useForm({
        defaultValues: {
          companyName: "",
          name: "",
          email: "",
          password: "",
          confirmPassword: "",
          equityPercent: 10,
          notes: "",
        },
      });

      const onSubmit = async data => {
        if (data.password === data.confirmPassword) {
          try {
            const userCredential = await createUserWithEmailAndPassword(auth, data.email, data.password);
            const user = userCredential.user;
            localStorage.setItem('uid', user.uid);
            localStorage.setItem('userType', 'startup');

            await setDoc(doc(db, "startups", user.uid), {
              companyName: data.companyName,
              name: data.name,
              email: data.email,
              equityPercent: data.equityPercent,
              notes: data.notes
            }, { merge: true })
            .then(navigate(`/home/${user.uid}`));
            
          } catch (error) {
            console.error(error);
          }
        } else {
          alert("Password and Confirm Password do not match");
        }
      };

    return (
        <>
        <Spacer y={1}/>
        <img src={Logo} alt="Logo" />
        <Container align="center" justify="center">
        <Text h1 size={60} 
            css={{textGradient: "45deg, $blue600 -20%, $pink600 50%",}}
            weight="bold">
            Startup Account Setup</Text>
        </Container>
        <Spacer y={1}/>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Container direction="column" align="center">
            <Controller
                name="companyName"
                control={control}
                render={({ field }) => (
                  <Input aria-label="companyName" label="Company Name" {...field} />
                )}
              />
                <Spacer y={1}/>
              <Controller
                name="name"
                control={control}
                render={({ field }) => (
                  <Input aria-label="name" label="Name" {...field} />
                )}
              />
              <Spacer y={1}/>
              <Controller
                name="email"
                control={control}
                render={({ field }) => (
                  <Input aria-label="email" label="Email"{...field} />
                )}
              />
              <Spacer y={1}/>
              <Container style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
              <Controller
                name="password"
                control={control}
                render={({ field }) => (
                  <Input.Password aria-label="password" label="Password"{...field} />
                )}
              />
               <Spacer y={1}/>
              <Controller
                name="confirmPassword"
                control={control}
                render={({ field }) => (
                  <Input.Password aria-label="confirmPassword" label="Confirm Password"{...field} />
                )}
              />
              </Container>
              <Spacer y={1}/>
              <Container style={{ display: "flex", justifyContent: "center", alignItems: "center"}}>
                <Controller
                    name="equityPercent"
                    control={control}
                    render={({ field }) => (
                    <EquityPercent aria-label="equityPercent" field={field} />)}
                />
                </Container>
              <Spacer y={1}/>
              <Controller
                name="notes"
                control={control}
                render={({ field }) => (
                  <Textarea
                    label="Notes (optional)"
                    placeholder="Please enter here"
                    aria-label="notes"
                    {...field}
                  />
                )}
              />
            <Spacer y={2}/>
            <Button type="submit">Continue</Button>
            </Container>
          </form>
        </>
  )}
