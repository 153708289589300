import { getFirestore } from "firebase/firestore";
import { initializeApp } from "firebase/app";

const firebaseConfig = {
  apiKey: "AIzaSyDNyksXNFpLbUawnuQm9LxldzJYSkEtk2Y",
  authDomain: "liquidex-c5a8f.firebaseapp.com",
  projectId: "liquidex-c5a8f",
  storageBucket: "liquidex-c5a8f.appspot.com",
  messagingSenderId: "679399205007",
  appId: "1:679399205007:web:c9d7d360df0d3504e3e8fa"
};

const app = initializeApp(firebaseConfig);

export { app };
export const  { db } = getFirestore(app);
