import { Navbar, Button, Text, Link } from "@nextui-org/react";
import { styled } from "@nextui-org/react";
import { doc, getDoc, getFirestore } from "firebase/firestore";
import { app } from "../firebase/config";
import { useState, useEffect } from "react";

const db = getFirestore(app);

const Box = styled("div", {
  boxSizing: "border-box",
});

const signOut = () => {
  localStorage.removeItem("uid");
  localStorage.removeItem("userType");
  window.location.reload();
};

const Layout = ({ children }) => (
  <Box css={{ maxW: "100%" }}>{children}</Box>
);

export default function NavBar() {
  const [balance, setBalance] = useState(0);

  useEffect(() => {
    const docRef = doc(db, "investors", localStorage.getItem("uid"));
    getDoc(docRef).then((doc) => {
      if (doc.exists()) {
        setBalance(doc.data().balance);
      }
    });
  }, []);

  const collapseItems = ["Equities", "Trade", "About"];

  const uid = localStorage.getItem("uid");
  return (
    <Layout>
      <Navbar isBordered variant="sticky">
        <Navbar.Brand>
          <Navbar.Toggle aria-label="toggle navigation" />
          <Text b color="inherit" hideIn="xs">
            LiquiDex
          </Text>
        </Navbar.Brand>
        <Navbar.Content hideIn="xs" variant="highlight-rounded">
          <Navbar.Link href={`/equities/${uid}`}>Equities</Navbar.Link>
          <Navbar.Link href={`http://localhost:3000/`}>Trade</Navbar.Link>
          <Navbar.Link href={`/about/${uid}`}>About</Navbar.Link>
        </Navbar.Content>
        <Navbar.Content>
          <Text>Balance: ${balance}</Text>
          <Navbar.Item>
            <Button
              color="gradient"
              auto
              ghost
              onClick={() => signOut()}
            >
              Sign Out
            </Button>
          </Navbar.Item>
        </Navbar.Content>
        <Navbar.Collapse>
          {collapseItems.map((item, index) => (
            <Navbar.CollapseItem key={item}>
              <Link
                color="inherit"
                css={{
                  minWidth: "100%",
                }}
                href={`/${item.toLowerCase()}/${uid}`}
              >
                {item}
              </Link>
            </Navbar.CollapseItem>
          ))}
        </Navbar.Collapse>
      </Navbar>
    </Layout>
  );
}
