import NavBar from "../Components/NavBar";
import { Avatar, Container, Card, Row, Text, Col, Spacer } from "@nextui-org/react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import blakeProfPic from "../assets/blakeprofpic.jpeg";
import aidenProfPic from "../assets/aidenprofpic.jpeg";

export default function AboutPage() {

  const navigate = useNavigate();

    useEffect(() => {
        if (localStorage.getItem("uid") === null) {
          navigate("/");
        }
        });

  return (
    <>
    <NavBar/>
    <Spacer y={1} />
      <Text h2 css={{ textGradient: "45deg, $blue800 -10%, $pink600 100%"}} weight="bold">The LiquiDex Team</Text>
    <Spacer y={1} />
    <Container fluid>
    <Row gap={1}>
        <Col>
          <Card css={{ $$cardColor: '$colors$primary' }}>
            <Card.Body>
                <div style={{"display": "flex", "flex-direction": "column", "align-items": "center"}}>
                    <Avatar size="80px" src={blakeProfPic}/>
                    <Text h2 color="white" css={{ m: 0 }}>Blake Germani</Text>
                </div>
            </Card.Body>
          </Card>
        </Col>
        <Col>
          <Card css={{ $$cardColor: '$colors$primary' }}>
            <Card.Body>
                <div style={{"display": "flex", "flex-direction": "column", "align-items": "center"}}>
                    <Avatar size="80px" src={aidenProfPic}/>
                    <Text h2 color="white" css={{ m: 0 }}>Aiden Nasca</Text>
                </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
    </>
  );
}